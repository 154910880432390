<template>
  <div class="wrapper">
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading"></vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import api from '@/store/API/api'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 80,
          align: 'center',
        },
        {
          field: 'pubOldPersonName',
          title: '姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
        {
          field: 'pubOldPersonTel',
          title: '电话',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'orgName',
          title: '机构名称',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'qrInTime',
          title: '打卡时间',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        }
      ],
      tableData: [],
    }
  },
  props: {
    personId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['queryElectLocks']),
    initView() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    getLists() {
      this.loading = true
      this.queryElectLocks({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          pubOldPersonId: this.personId,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
