<template>
  <div class="">
    <!--      <vxe-grid stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading"></vxe-grid>-->
    <div class="flex align-center justify-center bind-eq" @click="showDeviceBindingDialog">
      <img src="@/assets/images/smartElderly/开始刷卡.png" width="12px" height="12px">
      <div class="bind-eq-text">设备绑定</div>
    </div>
    <div :class="{'background-2D8CF0':form.bedDeviceId != null && form.bedDeviceId !== ''}">
      <table style="width: 100%" :class="{'tr-first':form.bedDeviceId != null && form.bedDeviceId !== ''}">
        <thead>
        <tr>
          <td width="14%" align="center">设备编号</td>
          <td width="12%" align="center">设备昵称</td>
          <td width="12%" align="center">老人姓名</td>
          <td width="12%" align="center">性别</td>
          <td width="12%" align="center">联系电话</td>
          <td width="12%" align="center">床位</td>
          <td align="center" width="100px">所属机构</td>
          <td align="center" width="100px">设备指示灯</td>
          <td align="center" width="100px">设备状态</td>
          <td align="center" width="180px">操作</td>
        </tr>
        </thead>
        <tbody v-if="form.bedDeviceId != null && form.bedDeviceId !== ''">
        <tr class="">
          <td align="center" class="color-2D8CF0" @click="goData">{{ form.bedDeviceId }}</td>
          <td align="center">{{ deviceInfo.deviceAlias }}</td>
          <td align="center">{{ form.name }}</td>
          <td align="center">{{ form.sex }}</td>
          <td align="center">{{ form.contract }}</td>
          <td align="center">{{ form.inPensOrgBedNum }}</td>
          <td align="center">{{ form.inPensOrgName }}</td>
          <td align="center">
            <el-switch @change="powerChange"
                       v-model="powerValue"
                       active-color="#2D8CF0"
                       inactive-color="#CACACA">
            </el-switch>
          </td>
          <td align="center">
            {{ powerStatus }}
          </td>
          <td>
            <div class="flex align-center justify-center">
              <div @click.stop="showDeviceBindingDialog" class="btn-1">
                编辑
              </div>
              <div @click.stop="unbind" class="btn-2">
                解除绑定
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="tr-second flex flex-column color-ff" v-if="form.bedDeviceId != null && form.bedDeviceId !== ''">
      <div class="flex align-center justify-between">
        <div>{{ deviceInfo.deviceAlias }}设备所绑定</div>
        <div class="bind-sensor" @click="bindSensor">绑定传感器</div>
      </div>
      <div v-for="(item,index) in sensorList" :key="index" class="flex align-center justify-between">
        <div>{{ item.sensorAlias }}<img @click="changeSensorNick(item)" src="@/assets/images/smartElderly/修改.png"
                                        style="margin-left: 16px" width="12px"
                                        height="14px"/></div>
        <div class="flex align-center">
          <div class="sensor-button" @click="setAlertValue(item)">设置预警值</div>
        </div>
      </div>
      <div v-for="(item,index) in addiDeviceList" :key="index" class="flex align-center justify-between">
        <div>{{ item.addiDeviceNick }}</div>
        <div class="flex align-center">
          <div class="sensor-button" @click="unbindSensor(item)">解除绑定</div>
        </div>
      </div>
    </div>

    <el-dialog title="设备绑定" :visible.sync="deviceBindingDialog" width="560px" :close-on-click-modal="false">
      <el-form ref="deviceBindingFrom" :rules="deviceBindingFromRule" :model="deviceBindingFrom" label-width="80px">
        <el-form-item label="设备ID" prop="deviceNick">
          <el-input v-model="deviceBindingFrom.deviceNick" placeholder="请输入">
            <template slot="append">
              <div class="flex align-center color-ff" @click="searchDevice">
                <i class="el-icon-search"></i>
                <div style="margin-left: 10px;">搜索</div>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="bedDeviceId">
          <el-input v-model="deviceBindingFrom.bedDeviceId" :readonly="true" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="设备昵称" prop="deviceAlias">
          <el-input v-model="deviceBindingFrom.deviceAlias" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="deviceBindingDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitDeviceBinding">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="传感器绑定" :visible.sync="sensorBindingDialog" width="560px" :close-on-click-modal="false">
      <el-form ref="sensorBindingForm" :rules="sensorBindingFormRule" :model="sensorBindingFrom" label-width="100px">
        <el-form-item label="传感器类型" prop="addiDeviceType">
          <el-select v-model="sensorBindingFrom.addiDeviceType" placeholder="请输入传感器类型" style="width: 100%">
            <el-option
                v-for="item in deviceType"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址码" prop="addiDeviceAddress">
          <el-input v-model="sensorBindingFrom.addiDeviceAddress" placeholder="请输入地址码"></el-input>
        </el-form-item>
        <el-form-item label="设备昵称" prop="addiDeviceNick">
          <el-input v-model="sensorBindingFrom.addiDeviceNick" placeholder="请输入设备昵称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="sensorBindingDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitSensorBinding">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改传感器" :visible.sync="sensorFormDialog" width="560px" :close-on-click-modal="false">
      <el-form ref="sensorForm" :rules="sensorFormRule" :model="sensorForm" label-width="100px">
        <el-form-item label="传感器昵称" prop="sensorAlias">
          <el-input v-model="sensorForm.sensorAlias" placeholder="请输入传感器昵称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="sensorFormDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitSensorForm">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="预警值设置" :visible.sync="alertValueDialog" width="560px" :close-on-click-modal="false">
      <el-form ref="equipmentForm" :rules="equipmentRule" :model="equipment" label-width="150px">
        <el-form-item label="离床预备时间" prop="leftBedPrepareSeconds">
          <div class="flex align-center">
            <el-input v-model="equipment.leftBedPrepareSeconds" placeholder="请输入"></el-input>
            <div class="alert-value">秒</div>
          </div>
        </el-form-item>
        <el-form-item label="上床预备时间" prop="leaveLeftBedPrepareSeconds">
          <div class="flex align-center">
            <el-input v-model="equipment.leaveLeftBedPrepareSeconds" placeholder="请输入"></el-input>
            <div class="alert-value">秒</div>
          </div>
        </el-form-item>
        <el-form-item label="离床时间阈值" prop="notBackThreshold">
          <div class="flex align-center">
            <el-input v-model="equipment.notBackThreshold" placeholder="请输入"></el-input>
            <div class="alert-value">分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="离床前最短在床时间" prop="notbackShortesttimeofonbed">
          <div class="flex align-center">
            <el-input v-model="equipment.notbackShortesttimeofonbed" placeholder="请输入"></el-input>
            <div class="alert-value">分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="体动太少报警阈值" prop="activityTooLittleThreshold">
          <div class="flex align-center">
            <el-input v-model="equipment.activityTooLittleThreshold" placeholder="请输入"></el-input>
            <div class="alert-value">次/小时</div>
          </div>
        </el-form-item>
        <el-form-item label="体动太多报警阈值" prop="activityTooMuchThreshold">
          <div class="flex align-center">
            <el-input v-model="equipment.activityTooMuchThreshold" placeholder="请输入"></el-input>
            <div class="alert-value">次/小时</div>
          </div>
        </el-form-item>
        <el-form-item label="心率异常报警阈值" prop="heartbeatRateAbnormalDuration">
          <div class="flex align-center">
            <el-input v-model="equipment.heartbeatRateAbnormalDuration" placeholder="请输入"></el-input>
            <div class="alert-value">秒</div>
          </div>
        </el-form-item>
        <el-form-item label="心率过低报警阈值" prop="heartbeatRateAbnormalLow">
          <div class="flex align-center">
            <el-input v-model="equipment.heartbeatRateAbnormalLow" placeholder="请输入"></el-input>
            <div class="alert-value">次/分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="心率过高报警阈值" prop="heartbeatRateAbnormalHigh">
          <div class="flex align-center">
            <el-input v-model="equipment.heartbeatRateAbnormalHigh" placeholder="请输入"></el-input>
            <div class="alert-value">次/分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="呼吸异常报警阈值" prop="heartbeatRateAbnormalDuration">
          <div class="flex align-center">
            <el-input v-model="equipment.heartbeatRateAbnormalDuration" placeholder="请输入"></el-input>
            <div class="alert-value">秒</div>
          </div>
        </el-form-item>
        <el-form-item label="呼吸过低报警阈值" prop="breathingRateAbnormalityLow">
          <div class="flex align-center">
            <el-input v-model="equipment.breathingRateAbnormalityLow" placeholder="请输入"></el-input>
            <div class="alert-value">次/分钟</div>
          </div>
        </el-form-item>
        <el-form-item label="呼吸过高报警阈值" prop="breathingRateAbnormalityHigh">
          <div class="flex align-center">
            <el-input v-model="equipment.breathingRateAbnormalityHigh" placeholder="请输入"></el-input>
            <div class="alert-value">次/分钟</div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="alertValueDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitAlertValueDialog">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="解除绑定" :visible.sync="unbindDialog" width="560px" :close-on-click-modal="false">
      <div class="flex align-center justify-center unbind-text">
        是否解除设备{{ form.bedDeviceId }}与{{ form.name }}的绑定？
      </div>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="unbindDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitUnbind">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="解除绑定" :visible.sync="unbindSensorDialog" width="560px" :close-on-click-modal="false">
      <div class="flex align-center justify-center unbind-text">
        是否解除设备{{ sensorName }}与{{ form.name }}的绑定？
      </div>
      <div slot="footer" class="flex align-center justify-center">
        <el-button @click="unbindSensorDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitUnbindSensor">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      deviceBindingDialog: false,
      unbindDialog: false,
      unbindSensorDialog: false,
      sensorId: '',
      sensorName: '',
      deviceBindingFrom: {
        deviceNick: '',
        bedDeviceId: '',
        deviceAlias: '',
      },
      deviceBindingFromRule: {
        deviceNick: [
          { required: true, message: '请输入设备ID', trigger: 'blur' }
        ],
        bedDeviceId: [
          { required: true, message: '请输入设备编号', trigger: 'blur' }
        ],
        deviceAlias: [
          { required: true, message: '请输入设备昵称', trigger: 'blur' }
        ],
      },
      sensorBindingDialog: false,
      sensorBindingFrom: {
        addiDeviceAddress: "",
        addiDeviceNick: "",
        addiDeviceType: "",
      },
      sensorBindingFormRule: {
        addiDeviceAddress: [
          { required: true, message: '请输入地址码', trigger: 'blur' }
        ],
        addiDeviceType: [
          { required: true, message: '请输入传感器类型', trigger: 'blur' }
        ],
      },
      alertValueDialog: false,
      equipment: {
        deviceId: "", // 设备id
        sensorId: "",// 传感器id
        sensorAlias: "",// 传感器别名
        sensorNick: "",// 传感器昵称
        leftBedPrepareSeconds: "",// 离床预备时间
        leaveLeftBedPrepareSeconds: "",// 上床预备时间
        notBackThreshold: "",// 离床未归报警中离床时间阈值
        notbackShortesttimeofonbed: "",// 离床未归报警中离床前最短在床时间
        notbackAlertintervals: "",// 离床未归事件报警时间区间（只作用于离床未归事件）
        activityTooLittleThreshold: "",// 体动太少报警阈值
        activityTooMuchThreshold: "",// 体动太多报警阈值
        heartbeatRateAbnormalDuration: "",// 心率异常持续时间
        heartbeatRateAbnormalLow: "",// 心率过低报警的阈值
        heartbeatRateAbnormalHigh: "",// 心率过高报警的阈值
        breathingRateAbnormalityDuration: "",// 呼吸异常持续时间
        breathingRateAbnormalityLow: "",// 呼吸过低报警的阈值
        breathingRateAbnormalityHigh: "",// 呼吸过高报警的阈值
        createUserId: "",// 新增人
        createTime: "",// 新增时间
        lastUpdUserId: "",// 最后更新人
        lastUpdTime: "",// 最后更新时间
      },
      equipmentRule: {
        leftBedPrepareSeconds: [{ required: true, message: '请输入', trigger: 'blur' }],
        leaveLeftBedPrepareSeconds: [{ required: true, message: '请输入', trigger: 'blur' }],
        notBackThreshold: [{ required: true, message: '请输入', trigger: 'blur' }],
        notbackShortesttimeofonbed: [{ required: true, message: '请输入', trigger: 'blur' }],
        activityTooLittleThreshold: [{ required: true, message: '请输入', trigger: 'blur' }],
        activityTooMuchThreshold: [{ required: true, message: '请输入', trigger: 'blur' }],
        heartbeatRateAbnormalDuration: [{ required: true, message: '请输入', trigger: 'blur' }],
        heartbeatRateAbnormalLow: [{ required: true, message: '请输入', trigger: 'blur' }],
        heartbeatRateAbnormalHigh: [{ required: true, message: '请输入', trigger: 'blur' }],
        breathingRateAbnormalityDuration: [{ required: true, message: '请输入', trigger: 'blur' }],
        breathingRateAbnormalityLow: [{ required: true, message: '请输入', trigger: 'blur' }],
        breathingRateAbnormalityHigh: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      sensorList: [],
      sensorFormDialog: false,
      sensorForm: {
        id: "",
        deviceId: "",// 设备ID
        sensorId: "",// 传感器id
        sensorAlias: "",// 传感器别名
      },
      sensorFormRule: {
        sensorAlias: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
      },
      deviceType: [],
      addiDeviceList: [],
      deviceInfo: {},
    }
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
    powerValue() {
      return this.deviceInfo.ledJson.Sections[0].PowerLed === 1
    },
    powerStatus() {
      return this.deviceInfo.state === 1 ? '在线' : '离线'
    }
  },
  watch: {
    form() {
      console.log(this.form)
      this.getDetailWithSensor()
    }
  },
  created() {
    this.getDetailWithSensor()
    this.getSysDictData()
  },
  methods: {
    ...mapActions(['addHomeOldPersonServe', 'detailWithSensor', 'deviceInfoListByNickname', 'additionalDeviceDelete',
      'additionalDeviceAdd', 'sensorInfoUpdate', 'getSysDict', 'deviceInfoUpdate', 'deviceInfoBind', 'deviceInfoUnBind']),
    changeSensorNick(item) {
      this.sensorForm.id = item.id;
      this.sensorForm.sensorId = item.sensorId;
      this.sensorForm.deviceId = item.deviceId;
      this.sensorForm.sensorAlias = item.sensorAlias;
      this.sensorFormDialog = true
    },

    submitSensorForm() {
      this.$refs['sensorForm'].validate((valid) => {
        if (valid) {
          this.sensorInfoUpdate(this.sensorForm).then((res) => {
            if (res.code === 200) {
              this.$XModal.message({
                message: '设置成功',
                status: 'success',
              })
              this.getDetailWithSensor()
              this.sensorFormDialog = false
            }
          })
        }
      })
    },
    goData() {
      this.$router.push({ path: "/smartElderlyPlat/weekReport", query: { id: this.form.id } })
    },
    getSysDictData() {
      this.getSysDict({ typeCode: 'bedAddiDeviceType' }).then((res) => {
        if (res.code === 200) {
          this.deviceType = res.data
        }
      })
    },
    searchDevice() {
      this.deviceInfoListByNickname({
        deviceNick: this.deviceBindingFrom.deviceNick,
      }).then((res) => {
        if (res.code === 200 && res.data != null && res.data.length > 0) {
          this.deviceBindingFrom.bedDeviceId = res.data[0].deviceId
        } else {
          this.deviceBindingFrom.bedDeviceId = ""
        }
      })
    },
    showDeviceBindingDialog() {
      this.deviceBindingFrom.bedDeviceId = this.form.bedDeviceId;
      this.deviceBindingFrom.deviceAlias = this.deviceInfo.deviceAlias;
      this.deviceBindingFrom.deviceNick = this.deviceInfo.deviceNick;
      this.deviceBindingDialog = true
    },
    submitDeviceBinding() {
      this.$refs['deviceBindingFrom'].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.deviceBindingFrom));
          data.deviceId = data.bedDeviceId
          data.oldPersonId = this.form.id;
          this.deviceInfoBind(data).then((res) => {
            if (res.code == 200) {
              this.form.bedDeviceId = this.deviceBindingFrom.bedDeviceId
              this.getDetailWithSensor()
              this.$XModal.message({
                message: '绑定成功',
                status: 'success',
              })
              this.deviceBindingDialog = false;
            }
          })
        } else {
          return false
        }
      })
    },

    unbind() {
      this.unbindDialog = true
    },
    submitUnbind() {
      this.deviceInfoUnBind({ oldPersonId: this.form.id }).then((res) => {
        if (res.code == 200) {
          this.form.bedDeviceId = ''
          this.$XModal.message({
            message: '解绑成功',
            status: 'success',
          })
          this.unbindDialog = false
        }
      })
    },

    getDetailWithSensor() {
      if (this.form.bedDeviceId === '' || this.form.bedDeviceId == null) {
        return
      }
      this.detailWithSensor(this.form.bedDeviceId).then((res) => {
        if (res.code === 200 && res.data != null && res.data.sensorList != null) {
          this.sensorList = res.data.sensorList
          this.addiDeviceList = res.data.addiDeviceList
          this.deviceInfo = res.data
        } else {
          this.sensorList = []
          this.addiDeviceList = []
        }
      })
    },

    bindSensor() {
      this.sensorBindingFrom.addiDeviceAddress = ""
      this.sensorBindingFrom.addiDeviceNick = ""
      this.sensorBindingFrom.addiDeviceType = ""
      this.sensorBindingDialog = true
    },

    submitSensorBinding() {
      this.$refs['sensorBindingForm'].validate((valid) => {
        if (valid) {
          const data = this.sensorBindingFrom
          data.deviceId = this.form.bedDeviceId
          this.additionalDeviceAdd(data).then((res) => {
            if (res.code === 200) {
              this.$XModal.message({
                message: '添加成功',
                status: 'success',
              })
              this.sensorBindingDialog = false
              this.getDetailWithSensor()
            }
          })
        }
      })
    },

    unbindSensor(item) {
      this.sensorId = item.id
      this.sensorName = item.addiDeviceNick
      this.unbindSensorDialog = true
    },

    submitUnbindSensor() {
      this.additionalDeviceDelete(this.sensorId).then((res) => {
        if (res.code === 200) {
          this.$XModal.message({
            message: '解绑成功',
            status: 'success',
          })
          this.getDetailWithSensor()
          this.sensorId = ''
          this.sensorName = ''
          this.unbindSensorDialog = false
        }
      })
    },

    setAlertValue(item) {
      this.equipment = item
      this.alertValueDialog = true
    },

    submitAlertValueDialog() {
      this.$refs['equipmentForm'].validate((valid) => {
        if (valid) {
          this.sensorInfoUpdate(this.equipment).then((res) => {
            if (res.code === 200) {
              this.$XModal.message({
                message: '设置成功',
                status: 'success',
              })
              this.getDetailWithSensor()
              this.alertValueDialog = false
            }
          })
        }
      })
    },

    powerChange(e) {
      this.deviceInfo.ledJson.Sections[0].PowerLed = e === false ? 0 : 1
      this.deviceInfo.ledJson.Sections[0].NetworkLed = e === false ? 0 : 1
      this.deviceInfo.ledJson.Sections[0].DataLed = e === false ? 0 : 1
      this.deviceInfoUpdate(this.deviceInfo).then((res) => {
        if (res.code === 200) {
          this.$XModal.message({
            message: '设置成功',
            status: 'success',
          })
        }
      })
    },


  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}

.wrapper {
  .datetime {
    display: flex;
    align-items: center;

    span {
      margin: 0 3px;
    }
  }
}

</style>
<style scoped>
.bind-eq {
  width: 118px;
  height: 36px;
  background: #2D8CF0;
  border-radius: 4px;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 12px;
}

.bind-eq-text {
  margin-left: 8px;
}

thead {
  height: 58px;
  background-color: #F5f5f5;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
}

tbody tr {
  height: 70px;
  background-color: #FFFFFF;
  font-size: 16px;
}

.tr-first {
  border-radius: 0 0 12px 12px;
  overflow: hidden;
}

.tr-second {
  width: calc(100% - 82px);
  background: #2D8CF0;
  padding: 0 41px;
  font-size: 16px;
}

.tr-second > div {
  height: 58px;
  display: flex;
  align-items: center;

}

.tr-second > div:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}

.background-2D8CF0 {
  background-color: #2D8CF0;
}

.unbind-text {
  color: #333333;
  font-size: 22px;
}

.bind-sensor {
  width: 88px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 8px;
  color: #2D8CF0;
  font-size: 14px;
}

.sensor-button {
  width: 88px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  font-size: 14px;
  color: #ffffff;
}


.alert-value {
  margin-left: 15px;
  width: 70px;
  color: #64666A;
  font-size: 14px;
}

/deep/ .el-table td.el-table__cell {
  border: none;
}

/deep/ .el-table::before {
  height: 0;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: #fff;
}

/deep/ .el-input-group__append {
  background-color: #2D8CF0;
  border: 1px solid #2D8CF0;
}

.btn-1 {
  color: #2D8CF0;
}

.btn-2 {
  margin-left: 36px;
  color: #F02D2F;
}

.el-button {
  padding: 9px 20px;
}

.el-button + .el-button {
  margin-left: 40px;
}
</style>
