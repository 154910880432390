<template>
  <div class="wrapper finance-record">
    <vxe-button 
      status="primary" 
      content="充值" 
      @click="addClick"
      style="margin: 0 0 6px 0;width:80px"></vxe-button>
    <vxe-table
      border
      resizable
      auto-resize
      :key="Math.random()"
      show-header-overflow
      show-overflow
      highlight-hover-row
      :data="financeRecordList">
      <vxe-table-column type="seq" title="序号" width="60" align="center"></vxe-table-column>
      <vxe-table-column field="createTime" title="充值时间" minWidth="200" align="center"></vxe-table-column>
      <vxe-table-column field="depositMoney" title="充值金额" minWidth="120" align="center"></vxe-table-column>
      <vxe-table-column field="depositType" title="充值方式" minWidth="120" align="center"></vxe-table-column>
      <vxe-table-column field="depositAddress" title="充值地址" minWidth="120" align="center"></vxe-table-column>
      <vxe-table-column field="personName" title="姓名" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="personTel" title="身份证号" minWidth="200" align="center"></vxe-table-column>
      <vxe-table-column field="accountType" title="账户类型" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="mark" title="备注" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column title="操作" width="100" fixed="right">
        <template #default="{ row }">
          <vxe-button type="text" status="primary" content="删除" @click="delMoneyRecord(row.id)"></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager 
        :current-page.sync="tablePage.currentPage" 
        :page-size.sync="tablePage.pageSize" 
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult" 
        :layouts="layouts" 
        @page-change="pageChange"></vxe-pager>
    </div>
  <!-- 充值记录的表单弹框 -->
  <el-dialog title="充值记录" :visible.sync="showFinanceRecord" width="60%" class="finance-modal">
    <el-form ref="financeRecordForm" :rules="financeRecordRules" :model="financeRecordForm" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="老人姓名">
            <el-input v-model="detailForm.name" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="12">
          <el-form-item label="老人联系方式">
            <el-input v-model="detailForm.contract" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="老人身份证号">
            <el-input v-model="detailForm.idNum" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="充值人姓名">
            <el-input v-model="financeRecordForm.fromName" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="充值账号">
            <el-input v-model="financeRecordForm.fromPhone" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="充值金额" prop="depositMoney">
            <el-input v-model="financeRecordForm.depositMoney" autocomplete="off" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="充值方式" prop="depositType">
            <el-input v-model="financeRecordForm.depositType" autocomplete="off" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="充值时间">
            <el-date-picker
              disabled
              v-model="financeRecordForm.depositTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="充值地址">
            <el-input v-model="financeRecordForm.depositAddress" autocomplete="off" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="账户名称">
            <el-input v-model="financeRecordForm.accountName" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号">
            <el-input v-model="financeRecordForm.accountId" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="充值流水号">
            <el-input v-model="financeRecordForm.flowNo" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="当前余额">
            <el-input v-model="financeRecordForm.accountBalance" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="账户类型">
            <el-input v-model="financeRecordForm.accountType" autocomplete="off" placeholder="请输入" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input v-model="financeRecordForm.mark" type="textarea" :rows="2" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showFinanceRecord = false">取 消</el-button>
      <el-button type="primary" @click="submitMoneyRecord">确 定</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from "vuex";
export default {
  name: 'FinanceRecord',
  props: {
    personId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      showFinanceRecord: false,
      financeRecordList: [],
      financeRecordForm: {
        depositAddress: '',
        depositMoney: '',
        depositType: '',
        mark: ''
      },
      detailForm: {},
      financeRecordRules: {
        depositMoney: [{ required: true, message: '请输入充值金额', trigger: 'blur' }],
        depositType: [{ required: true, message: '请输入充值方式', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState([
      "layouts"
    ]),
	},
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      "getMoneyRecordListServe",
      "addMoneyRecordServe",
      "updateMoneyRecordServe",
      "delMoneyRecordServe",
      "getHomePersonListServe",
      'detailHomeOldPersonServe',
      'queryMoneyPageByPersonServe'
    ]),
    initView() {
      this.getFinanceRecordList()
      
    },
    addClick() {
      this.getPersonDetail()
      this.showFinanceRecord = true
    },
    delMoneyRecord(id) {
      this.$XModal.confirm("您确定要删除该数据?").then(type => {
        if (type === "confirm") {
          console.log('执行删除操作');
          this.delete(id)
        }
			})
    },
    delete(id) {
      this.delMoneyRecordServe({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: "success"
          });
          this.getFinanceRecordList()
        }
      })
		},
    // 人员详情
    getPersonDetail() {
      let params = {
        id: this.personId,
      }
      this.detailHomeOldPersonServe(params).then((res) => {
        if (res.code == 200) {
          this.detailForm = res.data
        }
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == "size") {
        this.tablePage.currentPage = 1;
      } else {
        this.tablePage.currentPage = item.currentPage;
      }
      this.getFinanceRecordList();
    },
    getFinanceRecordList() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          pubOldPeronId: this.personId
        }
      }
      this.queryMoneyPageByPersonServe(params).then(res => {
        if(res.code == 200) {
          this.financeRecordList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
    },
    submitMoneyRecord() {
      this.$refs.financeRecordForm.validate((valid) => {
        if (valid) {
          let params = this.financeRecordForm
          params.pubOldPersonId = this.personId
          this.addMoneyRecordServe(params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '保存成功',
                status: 'success',
              })
              this.getFinanceRecordList()
              this.showFinanceRecord = false
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    
  }
}
</script>

<style lang="scss">
.finance-modal{
  .el-dialog__body{
    max-height: 400px;
    overflow: auto;
  }

}
</style>