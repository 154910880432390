<template>
  <div>
    <div>
      <el-button type="text" icon="el-icon-plus" @click="()=>{isplus = true }" v-if="!isplus"></el-button>
      <el-button type="text" icon="el-icon-minus" @click="()=>{isplus = false }" v-if="isplus"></el-button>
      <el-button type="primary" icon="el-icon-plus" @click="addProject()" v-if="!isshow&&isplus">添加改造档案</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="addConfirmInfo()" v-if="!isshow&&isplus">添加确认信息</el-button>
      <!--      <el-button type="primary" icon="fa fa-check" @click="addProject('edit')" v-if="isshow&&isplus">编辑</el-button>-->
      <!--      <el-button type="danger" icon="fa fa-check" @click="delProject()" v-if="isshow&&isplus">删除</el-button>-->


    </div>
    <div>
      <el-table
          :data="tableData"
          border
          show-overflow-tooltip
          style="width: 100%">
        <el-table-column align="center"
            type="index"
            width="50"
            label="序号">
        </el-table-column>
        <el-table-column align="center" prop="oldPersonName" label="服务对象">
          <template slot-scope="scope">
            <p class="link-a">{{ scope.row.oldPersonName }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="oldPersonSex" label="性别"></el-table-column>
        <el-table-column align="center" prop="oldPersonIdNum" label="身份证号"></el-table-column>
        <el-table-column align="center" prop="oldPersonContract" label="联系电话"></el-table-column>
        <el-table-column align="center" prop="oldPersonTypeNames" label="人员类别"></el-table-column>
        <el-table-column align="center" prop="oldPersonAddress" label="申请改造住宅地址"></el-table-column>
        <el-table-column align="center" prop="elderlyDataType" label="服务项目">
          <template slot-scope="scope">
            {{ scope.row.elderlyDataType === 'BASIC_INFO' ? '适老化改造基本信息确认' : '适老化改造档案' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="workerName" label="服务人员">
          <template slot-scope="scope">
            <p class="link-a" @click="toDetail('detail', scope.row)">{{ scope.row.workerName }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center"

            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <el-button type="text" size="small" class="el-icon-minus" @click="scope.row.ismore=false"
                       v-if="scope.row.ismore"></el-button>
            <el-button type="text" size="small" class="el-icon-plus" @click="scope.row.ismore=true"
                       v-if="!scope.row.ismore"></el-button>
            <el-button @click="editClick(scope.row)" v-if="scope.row.ismore" type="text" size="small">编辑</el-button>

            <el-button @click="delProject(scope.row)" v-if="scope.row.ismore" type="text" size="small">删除</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :width="'80%'" title="" :visible.sync="detailDialog" :show-close="false" :close-on-click-modal='false'>
      <div class="">

        <table class="table" v-if="elderlyDataType === 'ARCHIVE'">
          <caption class="text-center">适老化改造服务报告</caption>
          <tbody>
          <tr>
            <td class="td1111">
              <div class="userimg float-left">
                <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                     onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
              </div>
            </td>
            <td colspan="4">
              <div class="tableinfo">
                <div class="frow">
                  <div class="fcol w200">
                    <label> <span class="font14">姓</span> <span class="font14">名：</span>
                    </label> <label><span class="font14"><a>{{ table.oldPersonName }}</a></span></label>
                  </div>
                  <div class="fcol">
                    <label>报告编号：</label> <label><span class="font14"><a>{{ table.reformNo }}</a></span></label>
                  </div>

                </div>
                <div class="frow">
                  <div class="fcol w200">
                    <label>联系电话：</label> <label><span class="font14">{{ table.oldPersonContact }}</span></label>
                  </div>
                  <div class="fcol">
                    <label><span class="font14">性</span><span class="font14">别：</span></label> <label><span
                      class="font14">{{ table.oldPersonGender }}</span></label>
                  </div>
                </div>
                <div class="frow">
                  <div class="fcol">
                    <label>出生日期：</label> <label><span class="font14">{{ table.oldPersonBirthday }}</span></label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">服务机构：</span></td>
            <td class="text-left" colspan="2"><span class="font14">{{ table.reformOrgName }}</span></td>
            <td class="text-right"><span class="font14">类型：</span></td>
            <td class="text-left"><span class="font14">适老化改造</span></td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">服务方案：</span></td>
            <td class="text-left" colspan="2"><span class="font14"><a href="#">{{ table.plan }}</a></span></td>
            <td class="text-right"><span class="font14">评估员：</span></td>
            <td class="text-left">
              <span class="font14">{{ table.assessorName }}</span><br>
              <!-- <span class="font14">-</span> -->
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">初步评估结果：</span></td>
            <td class="text-left" colspan="4">
        <span class="font14">
            <a>{{ table.preEvalResult }}</a>
        </span>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">最终评估结果：</span></td>
            <td class="text-left" colspan="4">
              <span class="font14">{{ table.finalEvalResult }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">审核意见：</span></td>
            <td class="text-left" colspan="4">
              <span class="font14">{{ table.reviewComments }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">专家意见：</span></td>
            <td class="text-left" colspan="4"><span class="font14">{{ table.expertComments }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">适老化产品明细：</span></td>
            <td class="text-left" colspan="4">
              <span class="font14">{{ table.productDetail }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">改造前照片:</span></td>
            <td class="text-left" colspan="2">
              <div class="userimg float-left">
                <el-image
                    v-for="(item,index) in table.beforeImgUrls"
                    :key="index"
                    style="width: 100px; height: 100px"
                    :src="item"

                    :preview-src-list="[item]"
                ></el-image>
              </div>
            </td>
            <td class="text-right" style="width:10%"><span class="font14">改造后照片:</span></td>
            <td class="text-left" colspan="2">
              <div class="userimg float-left">
                <el-image
                    v-for="(item,index) in table.afterImgUrls"
                    :key="index"
                    style="width: 100px; height: 100px"
                    :src="item"

                    :preview-src-list="[item]"
                ></el-image>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">评估表，审批表，前后对比档案</span></td>
            <td class="text-left" colspan="4">
              <el-image
                  v-for="(item,index) in table.otherImgUrls"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item"

                  :preview-src-list="[item]"
              ></el-image>
            </td>
          </tr>
          <tr>
          </tr>
          </tbody>
        </table>
        <table class="table" v-if="elderlyDataType === 'BASIC_INFO'">
          <caption class="text-center">适老化改造基本信息确认</caption>
          <tbody>
          <tr>
            <td class="td1111">
              <div class="userimg float-left">
                <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                     onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
              </div>
            </td>
            <td colspan="4">
              <div class="tableinfo">
                <div class="frow">
                  <div class="fcol w300">
                    <label> <span class="font14">姓</span> <span class="font14">名：</span>
                    </label> <label><span class="font14"><a>{{ table.name }}</a></span></label>
                  </div>
                  <div class="fcol w300">
                    <label> <span class="font14">性</span> <span class="font14">别：</span>
                    </label> <label><span class="font14"><a>{{ table.sex }}</a></span></label>
                  </div>
                  <div class="fcol">
                    <label>户籍所在地：</label> <label><span
                      class="font14">{{ table.censusProvince }}{{ table.censusCity }}{{
                      table.censusArea
                    }}{{ table.censusStreet }}{{ table.censusCommunity }}{{
                      table.censusAddress
                    }}</span></label>
                  </div>

                </div>
                <div class="frow">
                  <div class="fcol w300">
                    <label>年龄：</label> <label><span class="font14">{{ table.age }}</span></label>
                  </div>
                  <div class="fcol w300">
                    <label>身份证号：</label> <label><span class="font14">{{ table.idNum }}</span></label>
                  </div>
                  <div class="fcol">
                    <label><span class="font14">类</span><span class="font14">别：</span></label> <label><span
                      class="font14">{{
                      table.personTypeNames
                    }}</span></label>
                  </div>
                </div>
                <div class="frow">
                  <div class="fcol w300">
                    <label>家庭联系人：</label> <label><span class="font14">{{ table.emergencyContactName }}</span></label>
                  </div>
                  <div class="fcol">
                    <label><span class="font14">联系方式：</span></label> <label><span class="font14">{{
                      table.emergencyContactTel
                    }}</span></label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">申请改造<br/>住宅地址</span></td>
            <td class="text-left" colspan="2"><span class="font14">{{ table.usualProvince }}{{
                table.usualCity
              }}{{ table.usualArea }}{{ table.usualStreet }}{{ table.usualCommunity }}{{ table.usualAddress }}</span>
            </td>
            <td class="text-right"><span class="font14">住宅情况</span></td>
            <td class="text-left"><span class="font14">{{ table.houseBelongOwn == 1 ? '自有' : '非自有' }}</span></td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">定位</span></td>
            <td class="text-left" colspan="2"><span class="font14">{{ table.location }}</span></td>
            <td class="text-right"><span class="font14">经纬度</span></td>
            <td class="text-left">
              <span class="font14" v-if="table.latitude != '' && table.latitude != null">{{ table.latitude }},{{ table.longitude }}</span><br>
              <!-- <span class="font14">-</span> -->
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">服务人员</span></td>
            <td class="text-left" colspan="2"><span class="font14"><a href="#"> {{ table.workerName }}</a></span></td>
            <td class="text-right"><span class="font14">备注</span></td>
            <td class="text-left">
              <span class="font14">{{ (table.remark == null || table.remark == '') ? '无' : table.remark }}</span><br>
              <!-- <span class="font14">-</span> -->
            </td>
          </tr>
          <tr>
            <td class="text-right"><span class="font14">图片</span></td>
            <td class="text-left" colspan="4">
              <el-image
                  v-for="(item,index) in table.imgInfoList"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item.url"
                  :preview-src-list="[item.url]"
              ></el-image>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{detailDialog = false,elderlyDataType=''}">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加适老化改造" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="form" label-position="left">
        <el-form-item label="服务机构" :label-width="formLabelWidth">
          <el-select v-model="form.reformOrgId" placeholder="请选择">
            <el-option
                v-for="item in orgDatas"
                :key="item.id"
                :label="item.compName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="类型" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item> -->
        <el-form-item label="服务方案" :label-width="formLabelWidth">
          <el-input v-model="form.plan" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="评估员姓名" :label-width="formLabelWidth">
          <el-input v-model="form.assessorName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="初步评估结果" :label-width="formLabelWidth">
          <el-input v-model="form.preEvalResult" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="最终评估结果" :label-width="formLabelWidth">
          <el-input v-model="form.finalEvalResult" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="审核意见" :label-width="formLabelWidth">
          <el-input v-model="form.reviewComments" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="专家意见" :label-width="formLabelWidth">
          <el-input v-model="form.expertComments" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="适老化产品明细" :label-width="formLabelWidth">
          <el-input v-model="form.productDetail" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="请选择状态" style="width:100%">
            <el-option
                label="合格"
                value="合格">
            </el-option>
            <el-option
                label="待验收"
                value="待验收">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="改造前照片" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="beforeImgsList" @uploadChange="onBeforeImg"></upload-img>
        </el-form-item>
        <el-form-item label="改造后照片" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="afterImgsList" @uploadChange="onAfterImg"></upload-img>
        </el-form-item>
        <el-form-item label="评估表，审批表，前后对比档案" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="otherImgsList" @uploadChange="onOtherImg"></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{dialogFormVisible = false,form={}}">取 消</el-button>
        <el-button type="primary" @click="addElderBtn()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加适老化改造基本信息" :visible.sync="confirmInDialog" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="confirmInForm" label-position="left">
        <el-form-item label="服务对象" prop="serviceOldPersonId" :label-width="formLabelWidth">
          <el-select filterable v-model="confirmInForm.oldPersonId" style="width:100%" :disabled="true"
                     placeholder="请选择服务对象">
            <el-option
                v-for="item in personList"
                :key="item.id"
                :label="item.personName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务人员" :label-width="formLabelWidth">
          <el-select v-model="confirmInForm.workerId" placeholder="请选择服务人员" style="width:100%">
            <el-option
                v-for="item in servicePersonList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="类型" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="confirmInForm.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="水印时间" :label-width="formLabelWidth">
          <el-date-picker
              style="width:100%;"
              v-model="confirmInForm.pcWatermarkTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
              @change="pcWatermarkTimeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="请拍照上传 老人室内照 片（至少三 张）" :label-width="formLabelWidth">
          <upload-img :limit="6" :uploadLists="confirmInfoImgList" @uploadChange="confirmInfoImg"></upload-img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{confirmInDialog = false,confirmInForm={}}">取 消</el-button>
        <el-button type="primary" @click="submitConfirmInfo()">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";

export default {
  components: {
    UploadImg
  },

  data() {
    return {
      size: 'small ',
      // oldPersonName:'aaa', //老人姓名
      table: {},
      detailDialog: false,
      isshow: '',
      dialogFormVisible: false,
      form: {
        oldPersonId: this.$route.params.id
      },
      formLabelWidth: '120px',
      beforeImgsList: [],
      afterImgsList: [],
      otherImgsList: [],
      orgDatas: [],
      isedit: false,
      isplus: false,
      id: '',
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableData: [],
      personList: [],
      confirmInFormEdit: false,
      servicePersonList: [],
      confirmInForm: {},
      confirmInDialog: false,
      confirmInfoImgList: [],
      elderlyDataType: ""
    };
  },
  created() {
    console.log(this.$route);
    this.querySumCenterList()
    this.getServicePersonList()
    this.getPersonList()
    this.getLists();
  },
  methods: {
    ...mapActions([
      'elderReformDetai',
      'getServeOrgsListServe',
      'addElderReform',
      'updateElderReform',
      'deleteElderReform',
      'getWorkPersonListServe',
      'getHomePersonListServe',
      'queryolderReformListAll',
      'queryolderReformListAdd',
      'queryolderReformListDelete',
      'queryolderReformListUpdate',
      'queryolderReformListDetail'
    ]),

    addProject() {
      this.isedit = false
      this.dialogFormVisible = true
      this.beforeImgsList = []
      this.afterImgsList = []
      this.otherImgsList = []
    },

    //查询列表
    getLists() {
      let params = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        oldPersonId: this.$route.query.oldPersonId
      }
      this.queryolderReformListAll(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.dataList
          this.tablePage.totalResult = res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'ismore', false)
          }
          console.log(this.tableData);
        }
      })
    },

    editClick(row) {
      if (row.elderlyDataType === 'BASIC_INFO') {
        this.confirmInFormEdit = true;
        this.queryolderReformListDetail({ id: row.id }).then(res => {
          if (res.code == 200) {
            this.confirmInForm = {
              id: res.data.id,
              oldPersonId: res.data.oldPersonId,
              imgs: res.data.imgs,
              workerId: res.data.workerId,
              remark: res.data.remark,
              needAddWatermarkImgIdSet: res.data.imgs
            }
            if (this.confirmInForm.imgs) {
              this.getFileDetail(this.confirmInForm.imgs, 4)
            }
          }
          this.confirmInDialog = true
        })
      } else {
        this.isedit = true
        let params = {
          id: row.id
        }
        this.elderReformDetai(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.isshow = true
            this.form = {
              ...res.data
            }
            console.log(this.form.beforeImgs);
            if (this.form.beforeImgs) {
              console.log(123213);
              this.getFileDetail(this.form.beforeImgs, 1)
            }
            if (this.form.afterImgs) {
              this.getFileDetail(this.form.afterImgs, 2)
            }
            if (this.form.otherImgs) {
              this.getFileDetail(this.form.otherImgs, 3)
            }
          }
        }).catch(err => {
          console.log(err);
        })

        this.dialogFormVisible = true
      }
    },

    // 获取服务对象
    getPersonList() {
      let params = {
        current: 1,
        size: 4000,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        if (res.code == 200) {
          this.personList = res.data.records;
        }
      })
    },

    /**
     * 获取服务人员
     */
    getServicePersonList() {
      this.getWorkPersonListServe({
        current: 1,
        size: 4000,
        param: {}
      }).then(res => {
        if (res.code == 200) {
          this.servicePersonList = res.data.records
        }
      })
    },
    /**
     * 添加确认信息按钮
     */
    addConfirmInfo() {
      this.confirmInFormEdit = false;
      this.confirmInDialog = true;
      this.confirmInfoImgList = []
      this.confirmInForm.oldPersonId = this.$route.query.oldPersonId
    },

    /**
     * 上传图片
     */
    confirmInfoImg(e) {
      console.log(e);
      this.confirmInForm.imgs = e.join(',');
    },
    /**
     * 水印时间
     */
    pcWatermarkTimeChange(e) {
      console.log(e)
    },
    /**
     * 确认信息提交
     */
    submitConfirmInfo() {
      if (this.confirmInFormEdit == false) {
        let data = this.confirmInForm
        if (!data.hasOwnProperty('imgs')) {
          this.$message.warning("图片最少三张")
          return
        }
        if (data.imgs.length < 3) {
          this.$message.warning("图片最少三张")
          return
        }
        if (!data.hasOwnProperty('needAddWatermarkImgIdSet')) {
          data.needAddWatermarkImgIdSet = []
        }
        let needAddWatermarkImgIdSet = [];
        data.imgs.split(",").forEach(item => {
          if (data.needAddWatermarkImgIdSet.indexOf(item) == -1) {
            needAddWatermarkImgIdSet.push(item);
          }
        })
        data.needAddWatermarkImgIdSet = needAddWatermarkImgIdSet
        data.fromPc = true;
        this.queryolderReformListAdd(data).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.confirmInForm = {}
            this.confirmInDialog = false
            this.getLists()
          }
        })
      } else {
        let data = this.confirmInForm
        if (!data.hasOwnProperty('imgs')) {
          this.$message.warning("图片最少三张")
          return
        }
        if (data.imgs.length < 3) {
          this.$message.warning("图片最少三张")
          return
        }
        if (!data.hasOwnProperty('needAddWatermarkImgIdSet')) {
          data.needAddWatermarkImgIdSet = []
        }
        let needAddWatermarkImgIdSet = [];
        data.imgs.split(",").forEach(item => {
          if (data.needAddWatermarkImgIdSet.indexOf(item) == -1) {
            needAddWatermarkImgIdSet.push(item);
          }
        })
        data.needAddWatermarkImgIdSet = needAddWatermarkImgIdSet
        data.fromPc = true;
        this.queryolderReformListUpdate(data).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.confirmInForm = {}
            this.confirmInDialog = false
            this.getLists()
          }
        })
      }
    },
    //删除
    delProject(row) {
      if (row.elderlyDataType === 'BASIC_INFO') {
        this.$XModal.confirm("您确定要删除该数据?").then(type => {
          if (type === "confirm") {
            let params = row.id
            this.queryolderReformListDelete(params).then(res => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getLists()

              }
            })
          } else {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          }
        })
      } else {
        this.$XModal.confirm("您确定要删除该数据?").then(type => {
          if (type === "confirm") {
            let params = row.id
            this.deleteElderReform(params).then(res => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getLists()

              }
            })
          } else {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          }
        })
      }
    },

    //查看图片详情
    getFileDetail(ids, type) {
      this.beforeImgsList = []
      this.afterImgsList = []
      this.otherImgsList = []
      this.confirmInfoImgList = []
      ids = ids.split(',')
      console.log(ids, type);
      ids.forEach(item => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            'Authorization': sessionStorage.token,
          },
        }).then((res) => {
          if (type == 1) {
            this.beforeImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else if (type == 2) {
            this.afterImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else if (type == 3) {
            this.otherImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else {
            this.confirmInfoImgList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          }
        })
      })
    },

    onBeforeImg(data) {
      this.form.beforeImgs = data.join(',')
    },
    onAfterImg(data) {
      this.form.afterImgs = data.join(',')
    },
    onOtherImg(data) {
      this.form.otherImgs = data.join(',')
    },
    handleClick(row) {
      let params = {
        id: row.id
      }
      this.elderlyDataType = row.elderlyDataType
      if (row.elderlyDataType === 'ARCHIVE') {
        this.elderReformDetai(params).then(res => {
          console.log(res);
          if (res.code == 200) {

            this.table = {
              ...res.data
            }
            this.id = res.data.id
            this.detailDialog = true
          }
        }).catch(err => {
          console.log(err);
        })
      } else if (this.elderlyDataType === 'BASIC_INFO') {
        this.queryolderReformListDetail(params, params.id).then(res => {
          if (res.code == 200) {

            this.table = {
              ...res.data
            }
            this.id = res.data.id
            this.detailDialog = true
          }
        })
      }
    },
    querySumCenterList() {
      this.getServeOrgsListServe({
        current: 1,
        size: 9999,
        param: {},
      }).then(res => {
        // console.log(res,'querySumCenterList');
        if (res.code == 200) {
          this.orgDatas = res.data.records
          console.log(this.orgDatas);
        }
        this.loading = false;
      })
    },
    //添加
    addElderBtn() {
      if (this.isedit) {
        let params = this.form
        this.updateElderReform(params).then(res => {
          if (res.code == 200) {
            this.$message.success("修改成功")
            this.form = {}
            this.dialogFormVisible = false
            this.queryDetail()
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        let params = this.form
        this.addElderReform(params).then(res => {
          if (res.code == 200) {
            console.log(res, '添加');
            this.$message.success("添加成功")
            this.form = {}
            this.dialogFormVisible = false
            this.queryDetail()

          }
        }).catch(err => {
          console.log(err);
        })
      }

    },

    toDetail(type, itemData) {
      itemData.id = itemData.workerId
      this.$router.push({
        name: 'WorkPersonDetail',
        params: {
          type: type,
          id: itemData.workerId,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
          workPersonId: type == 'add' ? 'add' : itemData.workerId
        },
      })
    },
  }
}
</script>

<style scoped>
.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  padding: 40px 0 0 50px;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: collapse;

}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}

.w300 {
  width: 300px;
}

/deep/ .el-image {
  width: 220px !important;
  height: 300px !important;
  margin: 32px 16px 0 0 !important;
}
</style>

