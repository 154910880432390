<template>
  <div class="health-report">
    <div class="health-report__title">
      <div class="title-row">
        <div class="item">
          <div class="label">姓名：</div>
          <div class="value">123123</div>
        </div>
        <div class="item">
          <div class="label">性别：</div>
          <div class="value">男</div>
        </div>
        <div class="item">
          <div class="label">年龄：</div>
          <div class="value">80</div>
        </div>
        <div class="item">
          <div class="label">手机号：</div>
          <div class="value">18888888888</div>
        </div>
        <div class="item">
          <div class="label">身份证号：</div>
          <div class="value">622622190001010202</div>
        </div>
      </div>
      <div class="title-row">
        <div class="item">
          <div class="label">报告号：</div>
          <div class="value">20210402151411412</div>
        </div>
        <div class="item">
          <div class="label">体检点位：</div>
          <div class="value">紫竹社区日间照料中心</div>
        </div>
        <div class="item">
          <div class="label">体检日期：</div>
          <div class="value">2021-09-30</div>
        </div>
      </div>
    </div>
    <div class="health-report__table">
      <el-table :data="tableData" :stripe="true" :header-cell-style="{ background: '#e0e4eb', color: '#333' }">
        <el-table-column align="center" prop="target" label="指标"></el-table-column>
        <el-table-column align="center" prop="result" label="本次结果"></el-table-column>
        <el-table-column align="center" prop="unit" label="单位"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HealthReport',
  props: {
    location: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      tableData: [
        {
          target: '身高',
          result: 188,
          unit: 'cm',
        },
        {
          target: '体重',
          result: 80,
          unit: 'kg',
        },
        {
          target: '体质指数	',
          result: 30,
          unit: '-',
        },
        {
          target: '脂肪率',
          result: 30,
          unit: '%',
        },
        {
          target: '腰围	',
          result: 80,
          unit: 'cm',
        },
        {
          target: '臀围	',
          result: 80,
          unit: 'cm',
        },
        {
          target: '腰臀比	',
          result: 100,
          unit: '%',
        },
        {
          target: '收缩压',
          result: 150,
          unit: 'mmHg',
        },
        {
          target: '舒张压',
          result: 64,
          unit: 'mmHg',
        },
        {
          target: '脉搏',
          result: 66,
          unit: '次/分',
        },
        {
          target: '血糖',
          result: '-',
          unit: 'mmol/L',
        },
        {
          target: '尿酸（嘌呤）',
          result: '-',
          unit: 'mmol/L',
        },
        {
          target: '总胆固醇',
          result: '-',
          unit: 'mmol/L',
        },
        {
          target: '体温',
          result: 36.4,
          unit: '℃',
        },
        {
          target: '血氧',
          result: 96,
          unit: '%',
        },
        {
          target: '心电	',
          result: '-',
          unit: '次/分',
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  font-size: 16px;
  color: #333;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #e0e4eb;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table td {
  border-bottom: none;
}
.health-report {
  &__title {
    border-top: 1px dashed #b5b5b5;
    border-bottom: 1px dashed #b5b5b5;
    background-color: #f9f9f9;
    padding: 10px 30px;
    margin-bottom: 10px;
    .title-row {
      width: 100%;
      font-size: 16px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .item {
        .label {
          float: left;
          color: #333;
        }
        .value {
          float: left;
          color: #71c3fb;
        }
      }
    }
  }
}
</style>
